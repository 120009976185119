import React, { Component } from 'react';
import { compose } from 'recompose';
import { ReturnFilteredDict } from '../../Modules/LoadCategoriesAndQualities';

import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../../Firebase';
import { AuthUserContext } from '../../Session';

import {  AutoSizer } from 'react-virtualized';
import {List} from 'react-virtualized';
import ManageQuestions from '../../Coach/ManageQuestions';

import _ from "lodash";
//expansion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';


// Modules for parameter change
//import SettingContentBase from '../Modules/SettingContentBase';
//import SettingContentActive from '../Modules/SettingContentActive';
//import SettingContentNumber from '../Modules/SettingContentNumber';
import SettingContentArray from '../Modules/SettingContentArray';
//import SettingContentImage from '../Modules/SettingContentImage';
//import SettingContentUploadImage from '../Modules/SettingContentUploadImage';
//import SettingContentRichText from '../Modules/SettingContentRichText';
//import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
//import SettingContentSelect from '../Modules/SettingContentSelect';


const styles = {
    root: {
        paddingLeft: 0,
        paddingRight: 0,
    },
};

class DialogKeyWords extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            cid: this.props.contentId,
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined) this.unsubscribe();
    }


    render() {
        return (
            <div>
                {this.props.showKeyWords !== false 
                &&
                    <Button onClick={() => this.handleOpenDialogueKeyWords()}>
                        Manage Keywords
                    </Button>
                } 
                &nbsp;
                <Button onClick={() => this.handleOpenDialogueQuestions()}>
                    Manage Questions
                </Button>
                {
                    this.dialogueKeyWords()
                }
                {
                    this.dialogueQuestions()
                }
            </div>
        );
    }

    handleCloseDialogueKeyWords = () => {
        this.setState({openDialogueKeyWords: false} )
    }
    handleOpenDialogueKeyWords = () => {
        this.props.firebase.content(this.state.cid + "/keyWords/tags" ).get()
        .then((doc) => {
            var identifiedTags = []
            if(doc){
                if(doc.data() !== undefined && doc.data().tags !== undefined){
                    identifiedTags = doc.data().tags;
                }
            }
            if(this._isMounted){
                this.setState({identifiedTags} );
            }
        })

        var activeTags = [];
        var activeTagsObjects = {};
        if(this.props.contentTags !== undefined ){
            activeTags = [...this.props.contentTags];
            //activeTagsObject = Object.assign(...activeTags.map(k => ({ ['tags_'+k.replace(/ /g,'')] : true })));
            activeTagsObjects =  Object.assign.apply(null, this.props.contentTags.map(k =>({['tags_'+k.replace(/ /g,'')]:true})));
        }
        if(this._isMounted){
            this.setState({openDialogueKeyWords: true, activeTags, activeTagsObjects: activeTagsObjects} )
        }

        if(this.props.selectedQualities !== undefined ){
            this.props.firebase.contents()
            .where('leadership_quality', 'array-contains-any', (this.props.selectedQualities || []))
            .get()
            .then((snapshot) => {
                if(snapshot){
                    var otherTags = [];
                    snapshot.forEach(doc => {
                        var tempTags = doc.data().tags;
                        //console.log("tempTags", tempTags)
                        if( tempTags !== undefined && tempTags.length>1 ){

                            //console.log("other tags", otherTags)
                            otherTags = _.union(otherTags, tempTags)
                        }
                    })
                    if(this._isMounted){
                        //console.log("other tags", otherTags)
                        this.setState({otherTags} );
                    }
                }
            })
        }
        //console.log('activtags', this.state)
    }
    handleCloseDialogueQuestions = () => {
        this.unsubscribeQuestions()
        this.setState({openDialogueQuestions: false} )
    }
    handleOpenDialogueQuestions = () => {
        var questionsGenerated = [{questions:[], questionsFinal:[]}]
        this.unsubscribeQuestions = this.props.firebase.content(this.state.cid + "/keyWords/questions" )
        .onSnapshot(doc => {
        //.get().then((doc) => {
            if(doc){
                questionsGenerated = doc.data();
                var availableQuestions = [];
                if(questionsGenerated['questions'] !== undefined ){
                    availableQuestions = questionsGenerated['questions'];
                }
                if(false && this.props.explanations !== undefined && this.props.explanations.length>0){
                    availableQuestions = availableQuestions.concat(this.props.explanations);
                }
                if(false && this.props.actions !== undefined && this.props.actions.length>0){
                    availableQuestions = availableQuestions.concat(this.props.actions);
                }
                questionsGenerated['questions'] = availableQuestions;
            }
            if(this._isMounted){
                this.setState({openDialogueQuestions: true, questionsGenerated} );
            }
        })
    }
    saveUpdatedKeyWords = () => {
        if (this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && this.state.activeTags.length>1) {
            var tags = this.state.activeTags
            tags.sort()
            this.props.firebase.content(this.state.cid ).update({tags : tags })
            .then( () => {
                this.handleCloseDialogueKeyWords()
            })
        }
    }

    updateActiveTags = (tag, activate) => {
        if (this._isMounted && this.state.activeTags!==undefined && Array.isArray(this.state.activeTags)) {
            //console.log('before',activate,  tag,  this.state.activeTags)
            var tagUpdate = this.state.activeTags
            if (activate){
                //tagUpdate.push(tag)
                tagUpdate = _.union(tagUpdate, [tag])
                this.setState({ activeTagsObjects : {...this.state.activeTagsObjects, ['tags_'+tag.replace(/ /g,'')] : true} })
            }
            else{

                this.setState({ activeTagsObjects : {...this.state.activeTagsObjects, ['tags_'+tag.replace(/ /g,'')] : false} })
                var index = tagUpdate.indexOf(tag);
                tagUpdate.splice(index, 1);
                tagUpdate = tagUpdate.filter((_, i) => i !== tag)
            }
            //console.log('after', tag,  this.state.activeTags)
            this.setState({activeTags : tagUpdate})
            //console.log('activtagsafter update', this.state)
        }
    }

    dialogueKeyWords = () => {
        return( 
            <RemoveScroll enabled={this.state.openDialogueKeyWords || false}>
                <Dialog
                    open={this.state.openDialogueKeyWords || false}
                    onClose={this.handleCloseDialogueKeyWords}
                    fullWidth={true}
                >
                    <DialogTitle  >
                        <IconButton onClick={this.handleCloseDialogueKeyWords}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span>
                            Key Word Management
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.openDialogueKeyWords
                            ?
                                <div>
                                    {this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && 
                                        <Button onClick={() => this.saveUpdatedKeyWords()}>
                                            Save Updated Keywords
                                        </Button>
                                    }
                                    {this.props.contentTags !== undefined &&
                                        <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="Account-Expand"
                                                aria-controls="Account-content"
                                                id="Account-header"
                                                >
                                                Current Active
                                            </AccordionSummary>
                                            <AccordionDetails> 
                                                <div style={{border:1}}>
                                                    <p>Active in Content</p>
                                                    {this.props.contentTags.map ( (currentTag, index) => (
                                                        <FormControlLabel
                                                            key={"currentTags_"+index}
                                                            id={"currentTags_"+index}
                                                            control={<Checkbox
                                                                onClick={(event) => this.updateActiveTags(currentTag, event.target.checked) }
                                                                checked = {
                                                                    this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+currentTag.replace(/ /g,'')] 
                                                                        ? true
                                                                        : false
                                                                }
                                                                //checked={ 
                                                                //    this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && this.state.activeTags.includes(currentTag)
                                                                //        ? true
                                                                //        : false
                                                                //} 
                                                                name={"currentTags_"+index} />}
                                                            label={currentTag}
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion> 
                                    }
                                    {this.state.identifiedTags !== undefined && this.state.identifiedTags !== null &&
                                        <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="Account-Expand"
                                                aria-controls="Account-content"
                                                id="Account-header"
                                                >
                                                Found tags in text
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{border:1}}>
                                                    <p>Found tags in text</p>
                                                    {this.state.identifiedTags.map ( (possibleTag, index) => (
                                                        <FormControlLabel
                                                            key={"possibleTag_"+index}
                                                            id={"possibleTag_"+index}
                                                            control={<Checkbox
                                                                onClick={(event) => this.updateActiveTags(possibleTag, event.target.checked) }
                                                                checked = {
                                                                    this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+possibleTag.replace(/ /g,'')] 
                                                                        ? true
                                                                        : false
                                                                }
                                                                //checked={ 
                                                                //    this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && this.state.activeTags.includes(possibleTag)
                                                                //        ? true
                                                                //        : false
                                                                //} 
                                                                name={"possibleTag_"+index} />}
                                                            label={possibleTag}
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>  
                                    }
                                    {this.state.otherTags !== undefined && this.state.otherTags !== null &&
                                        <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="otherqualities"
                                                aria-controls="otherqualities"
                                                id="otherqualities"
                                                >
                                                From other modules
                                            </AccordionSummary>
                                            <AccordionDetails>  
                                                <div style={{border:1}}>
                                                    <p>Found in other modules</p>
                                                    {this.state.otherTags.map ( (alternativeTag, index) => (
                                                        <FormControlLabel
                                                            key={"alternativeTag_"+index}
                                                            id={"alternativeTag_"+index}
                                                            control={<Checkbox
                                                                checked = {
                                                                    this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+alternativeTag.replace(/ /g,'')] 
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={(event) => this.updateActiveTags(alternativeTag, true) }
                                                                name={"alternativeTag_"+index} />}
                                                            label={alternativeTag}
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                </div>
                            :
                                ""
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

    dialogueQuestions = () => {
        //console.log(this.state.questionsGenerated)
        return(
            <RemoveScroll enabled={this.state.openDialogueQuestions || false}>
                <Dialog
                    open={this.state.openDialogueQuestions || false}
                    onClose={this.handleCloseDialogueQuestions}
                    //fullWidth={true}
                    fullScreen={true}
                >
                    <DialogTitle  >
                        <IconButton onClick={this.handleCloseDialogueQuestions}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span>
                            Question Inspiration Pool
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.openDialogueQuestions
                            ?
                                <div>
                                    <p>These questions are auto generated and have the purpose to inspire to make high quality questions.
                                        Once they are selected they will be added as inactive in the question pool. From there the questions can be activated and improved.
                                    </p>
                                    {
                                    ///// this failed with the options
                                    false && this.state.questionsGenerated !== undefined && this.state.questionsGenerated.questionIds !== undefined &&
                                        this.state.questionsGenerated.questionIds.length > 0 &&
                                        <>
                                            <ManageQuestions 
                                                searchByUtterIds = {this.state.questionsGenerated.questionIds}
                                                leadershipCategories={this.props.leadershipCategories}
                                                leadershipQualities={this.props.leadershipQualities} 
                                                user={this.context}
                                            />
                                        </>
                                    }
                                    <SettingContentArray
                                        dbUpdateLink={this.props.firebase.content(this.state.cid + "/keyWords/questions" )}
                                        title={"Overview of questions proposed"} 
                                        content={this.state.questionsGenerated} 
                                        params={['checked']}
                                        noLabel={true}
                                        access={false}
                                        />
                                    <p><strong>Each question is just an inspiration and usually needs to be modified, to use one inspirtion choose one of the 3 options below:</strong></p>
                                    <p><strong style={{color:'red'}}>1. AWARENESS QUESTION? - DAILY reflective question on new insights related to topic </strong></p>
                                    <p><strong style={{color:'red'}}>2. ACTION QUESTION? - DAILY activity question on upcoming or previous activities related to topic</strong></p>
                                    <p><strong style={{color:'red'}}>3. POWER QUESTION? - Any ONCE-A-WHILE knowledge or assessment question related to topic</strong></p>
                                    
                                    {this.state.questionsGenerated?.questions?.length > 0 && 
                                        <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                width={width}
                                                height={700}
                                                rowHeight={50}
                                                rowCount={this.state.questionsGenerated.questions.length}
                                                //overscanRowCount={10}
                                                rowRenderer={ ({index, style}) => {
                                                    return (
                                                        <div
                                                            style={style}
                                                            key={"alternativeTag_"+index}
                                                            id={"alternativeTag_"+index}
                                                        >
                                                            <div>
                                                                {/***
                                                                <Button size="small" onClick={() => {this.addQuestionToPool( this.state.questionsGenerated.questions[index],'mindset' )} }>
                                                                    Mindset</Button>
                                                                    &nbsp;
                                                                */}
                                                                <Button size="small" onClick={() => {this.addQuestionToPool( this.state.questionsGenerated.questions[index],'awareness' )} }>
                                                                    1. Thoughts</Button>
                                                                    &nbsp;
                                                                <Button size="small" onClick={() => {this.addQuestionToPool( this.state.questionsGenerated.questions[index],'activity' )} }>
                                                                    2. Actions</Button>
                                                                    &nbsp;
                                                                <Button size="small" onClick={() => {this.addQuestionToPool( this.state.questionsGenerated.questions[index],'quiz' )} }>
                                                                    3. Power Question</Button>
                                                                <span style={{padding: 20, color : this.state.questionsGenerated.checked !== undefined &&  this.state.questionsGenerated.checked.includes(this.state.questionsGenerated.questions[index])
                                                                    ? "green" : "darkgrey"}}>
                                                                    { this.state.questionsGenerated.questions[index]}
                                                                </span>
                                                            </div>

                                                        </div>
                                                    )
                                                }}
                                            >
                                            </List>
                                        )}
                                        </AutoSizer>
                                    }
                                </div>
                            :
                                ""
                        }
                    </DialogContent>
                </Dialog> 
            </RemoveScroll>
        )
    }



    addQuestionToPool = (question, type) => {
        var utterance_class = '';
        if(type === 'mindset') utterance_class = 'utter_ask_mindset';
        else if(type === 'activity') utterance_class = 'utter_ask_activity';
        else if(type === 'awareness') utterance_class = 'utter_ask_awareness';
        else if(type === 'quiz') utterance_class = 'utter_ask_quiz';

        if(utterance_class!== '' && question !== "" && question.length > 15){
            if(this.props.selectedCategory!== undefined && this.props.selectedCategory!=='' ){
                var newId = this.createObjectId();
                var category = this.props.selectedCategory;
                var explanation = '';
                if(this.props.explanations!== undefined && this.props.explanations!=='' ){
                    if(Array.isArray(this.props.explanations) && this.props.explanations.length > 0 ){
                        explanation = this.props.explanations[Math.floor(Math.random() * this.props.explanations.length)];
                    }
                    else{
                        explanation = this.props.explanation;
                    }
                }
                var qualities = this.props.selectedQualities;
                if(type === 'mindset' && this.props.leadershipQualities !== undefined && category.length===1 ){
                    var relatedQualities = ReturnFilteredDict(this.props.firebase, this.props.leadershipQualities, category?.[0], 'category');
                    if(relatedQualities!== undefined && relatedQualities.length > 0) {
                        qualities = relatedQualities.map( x => x.value);
                    }
                }
                var author = this.context.email;
                
                const dict = {
                    //status: 'inactive',
                    status: 'active',
                    agent: ['priming', 'reflection'],
                    template: {text: question, attachment: {explanation: explanation, utterance_class:utterance_class}},
                    author: [author],
                    utterance_class: utterance_class,
                    leadership: qualities,
                    category: category,
                    session: ['default'],
                    persona: ['default'],
                    mindset_type: ['default'],
                    mongodb_id: newId,
                    input_channels: ['rocky', 'firebase'],
                    day: ['default'],
                    day_time: ['default'],
                    sentiment: 'default'
                }
                console.log('created dict', dict)
                this.props.firebase.agentUtter(utterance_class, newId).set(dict, {merge:true } )
                .then( () => {
                    var questionSet = {
                        initialQuestion : question,
                        id : newId,
                        utterance : utterance_class
                    }
                    this.props.firebase.content(this.state.cid + "/keyWords/questions" ).update(
                        {
                            questionIds : this.props.firebase.fieldValue.arrayUnion(newId),
                            checked : this.props.firebase.fieldValue.arrayUnion(question),
                            questionSets : this.props.firebase.fieldValue.arrayUnion(questionSet)
                        }
                    )
                });
                //this.props.firebase.agentUtter(utterance_class, newId).set(dict, {merge:true } );
            }
        }    
    }
    createObjectId () {
        return this.createHex(Date.now() / 1000) +
            ' '.repeat(16).replace(/./g, () => this.createHex(Math.random() * 16))
    }
    createHex (value) {
        return Math.floor(value).toString(16)
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(DialogKeyWords);
