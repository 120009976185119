import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

class SettingContentBase extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.textFieldRef = React.createRef(); // Use createRef in the constructor
        this.state = {
            defaultValue: '',
            openSetting: false,
        };
    }

    componentWillUnmount(){
        this.handleSettingClose();
    }

    // Username Handling
    handleSettingOpen = (openDialogue=true) => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
                return this.setState({[p] : this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue});
            }
        })
        if(openDialogue){
            this.setState({
                openSetting: true,
            });
        }
    }

    handleSettingChange = (param, event, submit=false) => {
        var value = event.target.value;
        var failure = false;
        const cursorPosition = event.target.selectionStart; // Get current cursor position
        //console.log('cursorPosition', cursorPosition, value)
        if(false && this.props.titleFormat){
            const pointer = event.target.selectionStart;
            const element = event.target;
            window.requestAnimationFrame(() => {
                element.selectionStart = pointer;
                element.selectionEnd = pointer;
            });
            //console.log(value)
            value = "" + TitleCase(value);
            //console.log(value)
        }
        if(this.props.youTubeFormat){
            //console.log(value)
            value = "" + YouTubeCase(value);
            //console.log(value)
        }
        if(this.props.checkURLFormat){
            //value = value.substring(0, 5).toLowerCase() + value.substring(5);
            value = value.toLowerCase();
            if (!(value.startsWith("http://") || value.startsWith("https://"))) {
                this.setState({
                    [param]: value,
                    errorMessage: "Your URL must start with 'http' and ideally should be a secure link with 'https'"
                });
                failure = true;
            }
        }
        if(this.props.numberFormatCurrency){
            //console.log('value b', value) 
            value = value.replace(".","");
            value = value.replace(",","");
            value = value.replace(".","");
            value = value.replace(/[^0-9.]/g, "");
            //console.log('value a', value) 
        }
        if(failure){
        }
        else if(this.props.max!==undefined && this.props.max>0 ){
            if(event.target.value.length <= this.props.max){
                this.setState({
                    ...this.state,
                    [param]: value,
                    errorMessage: ''
                }, () => {
                    this.setCursorPosition(cursorPosition)
                    if(submit){
                        this.handleSettingSubmit()
                    }
                });
            }
            else{
                this.setState({
                    errorMessage: 'Text is too long - max ' + this.props.max + ' characters'
                });
            }
        }
        else{
            this.setState({
                ...this.state,
                [param]: value,
                errorMessage: ''
            }, () => {
                this.setCursorPosition(cursorPosition)
                if(submit){
                    this.handleSettingSubmit()
                }
            });
        }
    }

    setCursorPosition(cursorPosition){
        if(this.props.showRealTimeText && this.textFieldRef.current) {
            //console.log('filed else', cursorPosition)
            requestAnimationFrame(() => {
                this.textFieldRef.current.setSelectionRange(cursorPosition, cursorPosition);
            }, () => { // If requestAnimationFrame fails for some reason
                setTimeout(() => {
                    if (this.textFieldRef.current) {
                        this.textFieldRef.current.setSelectionRange(cursorPosition, cursorPosition);
                    }
                }, 0); // 0ms timeout, but it still executes after the current event loop
            });
        }
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue});
        })
    }

    handleSettingSubmit = () => {
        this.props.params.map((p) => {
            if(this.state[p] !== null && this.state[p] !== undefined && this.state[p] !== this.props.content[p] ){
                if(this.props.emptyOption || this.state[p] !== ""){
                    var newValue = this.state[p].trimStart();
                    if(this.props.showRealTimeText){
                        newValue = newValue.replace("  ", " ");
                    }
                    else{
                        newValue = newValue.trim();
                    }
                    var cancel = false;
                    if(this.props.numberFormatCurrency === true){
                        newValue = newValue.replace(".","");
                        newValue = parseInt(newValue.replace(/[^0-9.]/g, ''));
                    }
                    if(this.props.hexColorFormat){
                        var isHexColor  = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(newValue);
                        if(!isHexColor){
                            this.setState({
                                errorMessage: "Invalid color input"
                            });
                            cancel = true;
                        }
                        else{
                            this.setState({
                                errorMessage: ""
                            });
                        }
                    }
                    if(this.props.titleFormat){
                        var compareValue = '' + newValue; 
                        if(compareValue.slice(-1) === "."){
                            newValue = newValue.slice(0, -1);
                        }
                    }
                    if(this.props.checkURLFormat){
                        newValue = newValue.toLowerCase()
                        if (newValue.endsWith("/")) {
                            newValue = newValue.slice(0, -1);
                        }
                        if (!(newValue.startsWith("http://") || newValue.startsWith("https://"))) {
                            cancel = true
                        }
                    }
                    if(!cancel){
                        var updateSet = { [p]: newValue };
                        if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                            Object.assign(updateSet, this.props.updateExtraChange)
                        }
                        if(this.props.updateCopyTo !== undefined && typeof this.props.updateCopyTo === 'string'){
                            Object.assign(updateSet, {[this.props.updateCopyTo]: this.state[p]})
                        }
                        if(this.props.dbUpdateLink !== undefined){
                            this.props.dbUpdateLink.update(updateSet);
                        }
                        if(this.props.updateState !== undefined){
                            this.props.updateState(updateSet);
                        }
                    }
                }
            }
            return '';
        })        
        this.handleSettingClose();
    }

    render() {
        const { content, params, title , labels} = this.props;
        const { theme, classes } = this.props;
        //console.log('Contentbase state', this.state.comment)
        return (
        content
            ?
            <div className={classes.grow} style={{...(this.props.style || {})}}>
                <Card 
                    style={{
                        ...theme.cardAdminSettings, 
                        backgroundColor: ((this.props.access === false) ? 'transparent': theme.cardAdminSettings.backgroundColor), 
                        cursor: ((this.props.access === false) ? 'none' : 'pointer')
                    }} 
                    onClick={(this.props.access === undefined || this.props.access === true) ? this.handleSettingOpen : void(0)}
                >
                    <Grid container>
                        <Grid item xs={9} >
                            <h6><a to="#">{title}</a>
                            {this.props.mandatory==='ADMIN'
                                ? <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(Admin only)</span>
                                :   this.props.mandatory 
                                    ?   <strong style={{color:theme.palette.themeRed}}>&nbsp;*</strong>
                                    :   this.props.mandatory===false
                                        ?   <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(optional)</span>
                                        :   ""
                            }
                            </h6>
                        </Grid>
                        <Grid item xs={1} >  </Grid>
                        <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                            {(this.props.access === undefined || this.props.access === true)
                            ?
                                <Tooltip title={'Edit/Change'}>
                                    <IconButton onClick={this.handleSettingOpen}> 
                                        <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                    </IconButton>
                                </Tooltip>
                            :   <span style={{fontSize: 'small', fontWeight:'normal'}}>Read-only</span>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {this.props.showRealTimeText
                            &&
                                params.map((p, index) => (
                                    <div style={{width: `100%`}}  key={"real_"+p} 
                                        onClick={(event) => event.stopPropagation()}
                                    > 
                                        <TextField
                                            fullWidth
                                            inputRef={this.textFieldRef}
                                            style={ {fontSize: '16px'} }
                                            multiline={this.props.multiline || false}
                                            minRows={this.props.multiline ? 2 : 1} 
                                            key={"real"+p}
                                            id={"real"+p}
                                            label={((labels!==undefined ? labels[index] : p) + (this.props.max > 0 ? (" (" + (this.state[p]?.length || 0)+ "/"+ this.props.max+ " character)") : ""))}
                                            value={this.state?.[p] || content?.[p] || ""}
                                            onChange={(event) => {
                                                this.handleSettingChange(p, event, true)
                                            }}
                                        />
                                    </div>
                                ))
                            }
                            {
                            params.map((p, index) => (
                                <p style={{...theme.textSettings, position: 'relative'}} key={"current_"+p}>
                                    {this.props.showRealTimeText !== true
                                        &&
                                        <>
                                            {(this.props.showShort!==false && this.props.noLabel!== true)
                                                && <span style={{maxWidth: '20%'}}>{labels!==undefined ? labels[index] : p}</span>
                                            }
                                            <span style={{position: 'auto', textAlign: 'right', display: 'block', marginLeft: '25%', fontWeight: 700, right: 0, background: theme.palette.white+'5C', padding: 8, borderRadius: theme.borderRadius }}
                                            >
                                                {this.props.numberFormatCurrency === true
                                                    ?   
                                                        <strong>{content[p] > 0 ? (("" + content[p]).slice(0,-2) + "." + ("" + content[p]).slice(-2)) : "0.00"} ({this.props.currency || "USD"})</strong>
                                                    :
                                                        <strong>{content[p] || this.props.emptyStage || '[n/a]'}</strong>
                                                }
                                            </span>
                                        </>
                                    }
                                    {this.props.infoText?.[index]?.length > 2
                                        &&  <span style={theme.textSupportCenter}>{this.props.infoText[index]}</span>
                                    }
                                </p>
                            )) 
                            }
                        </Grid>
                    </Grid>
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle> {title || "Change Text"}</DialogTitle>
                    <form>
                        <DialogContent>
                            {
                                params.map((p, index) => (
                                    <div style={{width: `100%`, marginBottom: 40}} key={"change_"+p}> 
                                        <TextField
                                            fullWidth
                                            style={ {fontSize: '16px'} }
                                            multiline={this.props.multiline || false}
                                            minRows={this.props.multiline ? 2 : 1} 
                                            key={"change_"+p}
                                            id={"change_"+p}
                                            label={((labels!==undefined ? labels[index] : p) + (this.props.max > 0 ? (" (" + this.state[p]?.length+ "/"+ this.props.max+ " character)") : ""))}
                                            value={
                                                this.props.numberFormatCurrency
                                                    ?   (this.state[p] > 0 ? (("" + this.state[p]).slice(0,-2) + "." + ("" + this.state[p]).slice(-2)) : '')
                                                    :   this.state[p]
                                            }
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: this.props.numberFormatCurrency ? "right" : "left"},
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.handleSettingChange(p, e)
                                            }}
                                        />
                                        {this.props.infoText?.[index]?.length > 2
                                            &&  <span style={theme.textSupportCenter}>{this.props.infoText[index]}</span>
                                        }
                                    </div>
                                ))
                            }
                            {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                ?
                                    <p style={theme.textErrorCenterPadding}>
                                        {this.state.errorMessage}
                                    </p>
                                :   <p> </p>
                            }
                        </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        {this.state.errorMessage=== undefined || this.state.errorMessage===''
                        ?
                            <Button onClick={this.handleSettingSubmit}>
                                Save
                            </Button>
                        :
                            <Button disabled>
                                Save
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        : <div> </div>
                
        );
    }
}

SettingContentBase.propTypes = {
    classes: PropTypes.object.isRequired
};

export function YouTubeCase(url) {
    // Regex to match different YouTube url formats
    const youtubeRegex = /^.*((youtu\.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?v=))([^#&?]*).*/;
    // Match the url against the regex
    const match = url.match(youtubeRegex);
    if(url?.length > 3 && !(url.includes("http"))){
        return url;
    }
    // If there's a match, return the ID based on the captured group
    else if (match) {
        if (match[2] || match[3] || match.length === 2) { // Short url formats (youtu.be or v/) or just the code
            return match[6];
        } else if (match[4]) { // User specified id format (/u/.../-)
            return match[5];
        } else { // Watch?v= and embed formats
            return match[7];
        }
    } else {
        // Return an empty string if no match
        return "";
    }
}

export function TitleCase(input){
    var i, j, str, lowers, uppers;
    str = input.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  
    // Certain minor words should be left lowercase unless 
    // they are the first or last words in the string
    lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At', 
    'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'Per'];
    for (i = 0, j = lowers.length; i < j; i++)
        str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'), 
            function(txt) {
            return txt.toLowerCase();
            });
    // Certain words such as initialisms or acronyms should be left uppercase
    uppers = input.match(/(\b[A-Z][A-Z]+|\b[A-Z]\b)/g);
    //console.log(uppers)
    if (uppers?.length > 0){
        for (i = 0, j = uppers.length; i < j; i++){
            if(uppers[i]?.length > 1){
                var wordCheckCapitalFirst = uppers[i][0].toUpperCase() + uppers[i].slice(1).toLowerCase();
                str = str.replace(new RegExp('\\b' + wordCheckCapitalFirst + '\\b', 'g'), uppers[i].toUpperCase());
            }
        }
    }
    return str;
}

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentBase);
